import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/post-layout.js";
import SimpleCard from "../../demos/simple-card";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`hello`}</p>
    <p>{`there`}</p>
    <pre><code parentName="pre" {...{}}>{`code block
var x = function(a) { console.log('hello')}
`}</code></pre>
    <SimpleCard mdxType="SimpleCard" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      